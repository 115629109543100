import React from 'react';
import PropTypes from 'prop-types';
import {Box} from 'rebass';
import Header from './Header';
import Footer from './Footer';
import Pagination from './Pagination';
import Seo from './Seo';

const Layout = ({children, categories, seo, page}) => (
	<Box px={30} pt={25} mx="auto" width={1}>
		<Seo
			title={seo.title}
			description={seo.description}
			cover={seo.cover}
		/>
		<Header categories={categories}/>
		<Box mx="auto" css={{maxWidth: '1024px'}}>
			<main>{children}</main>
		</Box>
		<Pagination next={page.next} prev={page.prev}/>
		<Footer/>
	</Box>
);

Layout.propTypes = {
	children   : PropTypes.any.isRequired,
	categories : PropTypes.array.isRequired,
	seo        : PropTypes.object.isRequired,
	page       : PropTypes.object.isRequired
};

export default Layout;
