import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';

const LinkExtended = ({children, to, disabled}) => {
	if (disabled) {
		return children;
	}

	return (<Link to={to}>{children}</Link>);
};

LinkExtended.propTypes = {
	children : PropTypes.any.isRequired,
	to       : PropTypes.string.isRequired,
	disabled : PropTypes.bool
};

LinkExtended.defaultProps = {
	disabled : false
};

export default LinkExtended;
