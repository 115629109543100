import React from 'react';
import {Text, Link} from 'rebass';
const Footer = () => (
	<>
		<Text as="footer" fontSize={16} color="#242424" fontFamily="'Lora',serif" style={{textAlign : 'center'}}>Copyright ©&nbsp;2019 | <Link href="/rss.xml" style={{color : '#242424'}}>RSS</Link></Text>
		<svg id="sprite" xmlns="http://www.w3.org/2000/svg" version="1.0" style={{display: 'none'}}>
			<symbol id="icon-search" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 100 125" xmlSpace="preserve">
				<path d="M95.2,88.9L77.7,71.2c6-7.5,9.3-16.7,9.3-26.1c0-23-18.7-41.8-41.8-41.8c-23,0-41.8,18.8-41.8,41.9  c0,23,18.7,41.8,41.8,41.8c9.5,0,18.8-3.3,26.1-9.3l17.5,17.5c0.9,0.9,2,1.3,3.2,1.3s2.3-0.5,3.2-1.3c0.9-0.9,1.3-2,1.3-3.2  S96.1,89.7,95.2,88.9z M45.2,77.8c-18,0-32.7-14.6-32.7-32.6c0-18,14.7-32.7,32.7-32.7c18,0,32.7,14.7,32.7,32.7  C77.9,63.2,63.2,77.8,45.2,77.8z"/>
			</symbol>

			<symbol id="icon-right" viewBox="0 0 100 100">
				<path d="M32.59,78.41a2,2,0,0,0,2.83,0l29-29a2,2,0,0,0,0-2.83l-29-29a2,2,0,0,0-2.83,2.83L60.17,48,32.59,75.59A2,2,0,0,0,32.59,78.41Z"/>
			</symbol>

			<symbol id="icon-left" viewBox="0 0 96 96">
				<path d="M61.59,78.41a2,2,0,0,0,2.83-2.83L36.83,48,64.41,20.41a2,2,0,0,0-2.83-2.83l-29,29a2,2,0,0,0,0,2.83Z"/>
			</symbol>

			<symbol id="icon-twitter" viewBox="0 0 64 64">
				<g>
					<path d="M48,22.1c-1.2,0.5-2.4,0.9-3.8,1c1.4-0.8,2.4-2.1,2.9-3.6c-1.3,0.8-2.7,1.3-4.2,1.6 C41.7,19.8,40,19,38.2,19c-3.6,0-6.6,2.9-6.6,6.6c0,0.5,0.1,1,0.2,1.5c-5.5-0.3-10.3-2.9-13.5-6.9c-0.6,1-0.9,2.1-0.9,3.3 c0,2.3,1.2,4.3,2.9,5.5c-1.1,0-2.1-0.3-3-0.8c0,0,0,0.1,0,0.1c0,3.2,2.3,5.8,5.3,6.4c-0.6,0.1-1.1,0.2-1.7,0.2c-0.4,0-0.8,0-1.2-0.1 c0.8,2.6,3.3,4.5,6.1,4.6c-2.2,1.8-5.1,2.8-8.2,2.8c-0.5,0-1.1,0-1.6-0.1c2.9,1.9,6.4,2.9,10.1,2.9c12.1,0,18.7-10,18.7-18.7 c0-0.3,0-0.6,0-0.8C46,24.5,47.1,23.4,48,22.1z"/>
				</g>
			</symbol>

			<symbol id="icon-facebook" viewBox="0 0 64 64">
				<g transform="translate(16.983887, 16.983887)">
					<path d="M28.2916266,0 L1.65188365,0 C0.739854232,0 0,0.739854232 0,1.65188365 L0,28.2916266 C0,29.2049036 0.739854232,29.9435102 1.65188365,29.9435102 L15.9923298,29.9435102 L15.9923298,18.3478859 L12.0884446,18.3478859 L12.0884446,13.8301588 L15.9923298,13.8301588 L15.9923298,10.492705 C15.9923298,6.62624929 18.3553718,4.52271769 21.8051137,4.52271769 C23.458245,4.52271769 24.8793141,4.64373938 25.2935326,4.69863581 L25.2935326,8.7410097 L22.8968042,8.7410097 C21.0253348,8.7410097 20.6610221,9.64056265 20.6610221,10.9505912 L20.6610221,13.8339017 L25.1325863,13.8339017 L24.5524307,18.3628577 L20.6610221,18.3628577 L20.6610221,29.9435102 L28.2903789,29.9435102 C29.2049036,29.9435102 29.9435102,29.2049036 29.9435102,28.2916266 L29.9435102,1.65188365 C29.9435102,0.739854232 29.2049036,0 28.2916266,0" id="Shape"/>
				</g>
			</symbol>
		</svg>
	</>
);

export default Footer;
