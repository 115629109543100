import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

const SEO = ({title, description, cover}) => (
	<Helmet
		title={title}
		meta={[
			{name : 'description', content : description},

			{property : 'og:locale', content : 'en_US'},
			{property : 'og:type', content : 'website'},
			{property : 'og:title', content : title},
			{property : 'og:description', content : description},
			{property : 'og:image', content : cover},

			{name : 'twitter:card', content : 'summary_large_image'},
			{name : 'twitter:description', content : description},
			{name : 'twitter:title', content : title},
			{name : 'twitter:image', content : cover},

			{name : 'msapplication-TileImage', content : '/favicon/ms-icon-144x144.png'}
		]}
		link={[
			{rel : 'apple-touch-icon', sizes : '57x57', href : '/favicon/apple-icon-57x57.png'},
			{rel : 'apple-touch-icon', sizes : '60x60', href : '/favicon/apple-icon-60x60.png'},
			{rel : 'apple-touch-icon', sizes : '72x72', href : '/favicon/apple-icon-72x72.png'},
			{rel : 'apple-touch-icon', sizes : '76x76', href : '/favicon/apple-icon-76x76.png'},
			{rel : 'apple-touch-icon', sizes : '114x114', href : '/favicon/apple-icon-114x114.png'},
			{rel : 'apple-touch-icon', sizes : '120x120', href : '/favicon/apple-icon-120x120.png'},
			{rel : 'apple-touch-icon', sizes : '144x144', href : '/favicon/apple-icon-144x144.png'},
			{rel : 'apple-touch-icon', sizes : '52x152', href : '/favicon/apple-icon-52x152.png'},
			{rel : 'apple-touch-icon', sizes : '180x180', href : '/favicon/apple-icon-180x180.png'},

			{rel : 'icon', type : 'image/png', sizes : '192x192', href : '/favicon/android-icon-192x192.png'},
			{rel : 'icon', type : 'image/png', sizes : '32x32', href : '/favicon/android-icon-32x32.png'},
			{rel : 'icon', type : 'image/png', sizes : '96x96', href : '/favicon/android-icon-96x96.png'},
			{rel : 'icon', type : 'image/png', sizes : '16x16', href : '/favicon/android-icon-16x16.png'}
		]}
	>
		<html lang="en"/>
	</Helmet>
);

SEO.propTypes = {
	description : PropTypes.string.isRequired,
	cover   : PropTypes.string.isRequired,
	title   : PropTypes.string.isRequired
};

export default SEO;
