import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';
import {Box} from 'rebass';

const Pagination = ({prev, next}) => (
	<Box style={{textAlign: 'center', marginTop: '80px'}}>
		{prev && (
			<Link to={(prev === 1 ? '/' : '/page/' + prev)}>
				<svg role="img" style={{width : 50, height : 50}}> <use xlinkHref="#icon-left"/> </svg>
			</Link>
		)}
		{next && (
			<Link to={'/page/' + next}>
				<svg role="img" style={{width : 50, height : 50}}> <use xlinkHref="#icon-right"/></svg>
			</Link>
		)}
	</Box>
);

Pagination.propTypes = {
	prev : PropTypes.number,
	next : PropTypes.number
};

Pagination.defaultProps = {
	prev : null,
	next : null
};

export default Pagination;
