import React from 'react';
import PropTypes from 'prop-types';
import {Box, Text} from 'rebass';
import {Heading, Link, Reading} from './Text';
import Img from 'gatsby-image';

const Post = ({post}) => (
	<Box as="article" mx="auto" mb={75} width={[1, 0.8]}>
		<Box mx="auto" width={[1, 0.8]}>
			<Link to={post.fields.slug} disabled={Boolean(post.html)}>
				<Heading>{post.frontmatter.title}</Heading>
			</Link>
			<Text
				fontSize={[15, 18]}
				fontFamily="'Heebo', sans-serif"
				fontWeight="500"
				color="#535353"
				mb={[20, 45]}
			>
				{post.frontmatter.date}
				<Text as="span" mx="5px" fontSize={30} style={{verticalAlign: 'middle'}}>&#183;</Text>
				{post.fields.readingTime.text}
			</Text>
		</Box>
		<Link to={post.fields.slug} disabled={Boolean(post.html)}>
			<Img fluid={post.frontmatter.cover.childImageSharp.fluid} alt={post.frontmatter.title}/>
		</Link>
		<Reading mx="auto" width={[1, 0.8]}>
			{post.html && (<div className="content" dangerouslySetInnerHTML={{__html: post.html}}/* eslint-disable-line *//>)}
			{!post.html && post.excerpt}
		</Reading>
	</Box>
);

Post.propTypes = {
	post : PropTypes.shape({
		html : PropTypes.string,
		fields : PropTypes.shape({
			slug : PropTypes.string.isRequired,
			readingTime : PropTypes.shape({text : PropTypes.string}).isRequired
		}).isRequired,
		frontmatter : PropTypes.shape({
			title : PropTypes.string.isRequired,
			date : PropTypes.string.isRequired,
			cover : PropTypes.shape({publicURL : PropTypes.string}).isRequired
		}).isRequired
	}).isRequired
};

export default Post;
