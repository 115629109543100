import React from 'react';
import PropTypes from 'prop-types';
import {Text} from 'rebass';

const Reading = ({children, mx, width}) => (
	<Text
		mx={mx}
		width={width}
		fontSize={[17, 19]}
		fontFamily="'Lora',serif"
		color="#242424"
		mt={40}
		lineHeight="1.8"
	>
		{children}
	</Text>
);

Reading.propTypes = {
	children : PropTypes.any.isRequired,
	mx       : PropTypes.string.isRequired,
	width    : PropTypes.array.isRequired
};

export default Reading;
