import React from 'react';
import PropTypes from 'prop-types';
import {Text} from 'rebass';

const Heading = ({children}) => (
	<Text
		as="h1"
		fontSize={[4, 5, 55]}
		fontFamily="'Heebo', sans-serif"
		fontWeight="800"
		lineHeight="1.25"
		color="#242424"
	>
		{children}
	</Text>
);

Heading.propTypes = {
	children : PropTypes.any.isRequired
};

export default Heading;
