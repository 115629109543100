import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';
import {Flex, Box, Text} from 'rebass';
import {BrowserView, MobileView} from 'react-device-detect';
import HamburgerMenu from 'react-hamburger-menu';

class Header extends React.Component {
	state = {
		isOpen: false
	};

	openMenu = () => this.setState(({isOpen}) => ({isOpen : !isOpen}));

	render() {
		return (
			<>
				<Flex as="header" mb={[50, 100]}>
					<Link to="/">
						<Text
							fontSize={[3, 4, 5]}
							fontFamily="'Heebo', sans-serif"
							fontWeight="800"
							lineHeight="50px"
							color="#242424"
						>
							Blooms To <Text as="span" fontFamily="'Lora',serif">Bites</Text>
						</Text>
					</Link>
					<Box mx="auto"/>
					<BrowserView renderWithFragment>
						{this.props.categories.map(category => (
							<Link key={category.slug} to={category.slug}>
								<Text
									fontFamily="'Heebo', sans-serif"
									px="10px"
									fontSize="18px"
									color="#242424"
									lineHeight="50px"
								>
									{category.name}
								</Text>
							</Link>
						))}
					</BrowserView>
					<MobileView style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
						<HamburgerMenu
							isOpen={this.state.isOpen}
							menuClicked={this.openMenu}
							animationDuration={0.2}
							width={25}
							height={25}
						/>
					</MobileView>
				</Flex>
				{this.state.isOpen && (
					<Box width="100%" bg="white" style={{height : '100%', opacity : 0.95, position : 'absolute', top : 100, left : 30, zIndex: 10}}>
						{this.props.categories.map(category => (
							<Link key={category.slug} to={category.slug}>
								<Text
									fontFamily="'Heebo', sans-serif"
									color="#242424"
									fontSize="24px"
									py="5px"
								>
									{category.name}
								</Text>
							</Link>
						))}
					</Box>
				)}
			</>
		);
	}
}

Header.propTypes = {
	categories : PropTypes.array.isRequired
};

export default Header;
